@import "~jsoneditor/dist/jsoneditor.min.css";

.mycontent {
  padding: 20px 30px 30px 280px !important;
}

.top-nav {
  position: relative;
  padding: 65px 30px 0 130px !important;
}

div.jsoneditor,
div.textarea.jsoneditor-text {
  height: 400px;
}
div.jsoneditor,
div.jsoneditor-menu {
  border-color: #4b4b4b;
}
div.jsoneditor-menu {
  background-color: #4b4b4b;
}
div.jsoneditor-tree,
div.jsoneditor textarea.jsoneditor-text {
  background-color: #666666;
  color: #ffffff;
}
div.jsoneditor-field,
div.jsoneditor-value {
  color: #ffffff;
}
table.jsoneditor-search div.jsoneditor-frame {
  background: #808080;
}

tr.jsoneditor-highlight,
tr.jsoneditor-selected {
  background-color: #808080;
}

div.jsoneditor-field[contenteditable="true"]:focus,
div.jsoneditor-field[contenteditable="true"]:hover,
div.jsoneditor-value[contenteditable="true"]:focus,
div.jsoneditor-value[contenteditable="true"]:hover,
div.jsoneditor-field.jsoneditor-highlight,
div.jsoneditor-value.jsoneditor-highlight {
  background-color: #808080;
  border-color: #808080;
}

div.jsoneditor-field.highlight-active,
div.jsoneditor-field.highlight-active:focus,
div.jsoneditor-field.highlight-active:hover,
div.jsoneditor-value.highlight-active,
div.jsoneditor-value.highlight-active:focus,
div.jsoneditor-value.highlight-active:hover {
  background-color: #b1b1b1;
  border-color: #b1b1b1;
}

div.jsoneditor-tree button:focus {
  background-color: #868686;
}

/* coloring of JSON in tree mode */
div.jsoneditor-readonly {
  color: #acacac;
}
div.jsoneditor td.jsoneditor-separator {
  color: #acacac;
}
div.jsoneditor-value.jsoneditor-string {
  color: #00ff88;
}
div.jsoneditor-value.jsoneditor-object,
div.jsoneditor-value.jsoneditor-array {
  color: #bababa;
}
div.jsoneditor-value.jsoneditor-number {
  color: #ff4040;
}
div.jsoneditor-value.jsoneditor-boolean {
  color: #ff8048;
}
div.jsoneditor-value.jsoneditor-null {
  color: #49a7fc;
}
div.jsoneditor-value.jsoneditor-invalid {
  color: white;
}
div.jsoneditor-menu > a {
  visibility: hidden;
}

.btn-none {
  pointer-events: none;
}

.company-name {
  margin: 0;
  display: flex;
  align-items: center;
}

#contract-synopsis-tabset {
  > ul {
    justify-content: flex-start !important;

    > li {
      a {
        padding: 0 10px;
        > i {
          font-size: 20px;
          line-height: 40px;
        }
      }
    }
  }
}

.black-card-bg {
  background: #191f31;
}

body > bs-datepicker-container {
  margin-top: 10px;
  div
    > div
    > div
    > div
    > bs-month-calendar-view
    > bs-calendar-layout
    > div.bs-datepicker-head
    > bs-datepicker-navigation-view {
    display: flex;
    justify-content: space-between;
  }
}

.bs-datepicker-body table td.week span {
  color: #fd5d93;
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-head,
.bs-datepicker-head,
.bs-datepicker button:active,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*="select-"]:after,
.bs-datepicker-body table td[class*="select-"] span:after,
.bs-datepicker-body table td.active-week span:hover {
  background: linear-gradient(0deg, #ec250d 0%, #fd5d93 100%);
}

input[type="file"]::file-selector-button,
input[type="file"]::-webkit-file-upload-button {
  height: 100%;
  cursor: pointer;
}

.plan-title {
  position: relative;
  font-size: 3em;
  font-weight: 900;
  text-transform: lowercase;
  margin-left: -5px;
  z-index: 1;
}

.recommended {
  box-shadow: none;
  color: #ffffff;
  border-width: 2px;
  border: none;
  position: relative;
  overflow: hidden;
  margin: 4px 1px;
  border-radius: 0.4285rem;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding: 11px 20px;
  font-size: 0.875rem;
  line-height: 1.35em;
}

.recommended-no {
  background: #bdc3c7;
  background-image: linear-gradient(to right, #2c3e50, #bdc3c7);
  background-size: 210% 210%;
  background-position: top right;
  background-color: #bdc3c7;
}

.recommended-yes {
  background: #00f2c3;
  background-image: linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3);
  background-size: 210% 210%;
  background-position: top right;
  background-color: #00f2c3;
}

.small-card-border {
  border: solid 1px #2b3553;
  border-radius: 10px;
}

.market-plans {
  overflow-x: scroll;
  flex-wrap: unset;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}

.login-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 120px;
}

.login-logo {
  height: 100%;
}

.loader {
  position: absolute;
  left: calc(50% - 20px);
  top: 10%;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #fd5d93;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-sm {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #ccc;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.table-fixed-header {
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  tbody {
    display: block;
    overflow-y: auto;
    table-layout: fixed;
    max-height: 200px;
    &::-webkit-scrollbar {
      background-color: #fff;
      border-radius: 15px;
      opacity: 0.3;
      width: 15px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 10px;
      border: 2.5px solid #fff;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }
}

.page-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: #1e1e2f;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: transparent;
  border-radius: 50%;
  display: inline-block;
  border: 0.0625rem solid;
}

.tab-nav {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  padding: 0;
  margin: 0;
  text-align: center;
  list-style-type: none;
  list-style-type: none;
  li {
    display: flex;
    margin-bottom: 15px;

    span {
      cursor: pointer;
      border: none !important;
      border-radius: 0.25rem;
      background-color: #191f31;
      font-weight: 600;
      padding: 0;
      font-size: 0.875rem;
      line-height: 1.35em;
      text-align: center;
      vertical-align: middle;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      &:hover {
        background-color: #111520;
      }
      &.active {
        background: #fd5d93;
        background-image: linear-gradient(
          to bottom left,
          #fd5d93,
          #ec250d,
          #fd5d93
        );
        background-size: 210% 210%;
        background-position: top right;
        background-color: #fd5d93;
        transition: all 0.15s ease;
        box-shadow: none;
        color: #ffffff;
      }

      span {
        padding: 11px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        border-radius: 0;
        &:hover {
          background: transparent;
          backdrop-filter: brightness(0.8);
        }
      }
    }
  }
}

.multiselect-dropdown .disabled {
  span {
    background: none !important;
    opacity: 0.25;
  }
}

ngx-datatable {
  .datatable-row-wrapper {
    border-top: 0.0625rem solid #e3e3e3;
    border-color: rgba(255, 255, 255, 0.1);
  }

  &.ngx-datatable.bootstrap
    .datatable-body
    .datatable-body-row
    .datatable-body-cell {
    margin: auto;
    // padding: 7px 5px;
    color: $white;
    padding: 12px 7px;
    vertical-align: middle;
  }
  &.ngx-datatable.bootstrap .datatable-footer {
    .pager {
      margin: 2px 0;
      white-space: nowrap;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
      display: -ms-flexbox;
      display: flex;
      padding-left: 0;
      list-style: none;
      border-radius: 0.25rem;
      li a {
        border: 0;
        border-radius: 30px !important;
        transition: all 0.3s;
        padding: 0px 11px;
        margin: 0 3px;
        min-width: 30px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        color: $white;
        cursor: pointer;
        font-size: 14px;
        text-transform: uppercase;
        background: transparent;
        outline: none;
        z-index: 1;
        position: relative;
        display: block;
      }
      li.disabled {
        opacity: 0.5;
      }
    }
    .page-count {
      color: $white;
    }
  }
  &.ngx-datatable.bootstrap .datatable-footer .datatable-pager .pager li a i {
    border-radius: 30px !important;
    transition: all 0.3s;
    line-height: 35px;
  }
  &.ngx-datatable.bootstrap
    .datatable-footer
    .datatable-pager
    ul
    li:not(.disabled).active
    a {
    background-color: $danger;
    color: $white;
    font-weight: inherit;
  }
  &.ngx-datatable.bootstrap
    .datatable-footer
    .datatable-pager
    ul
    li:not(.disabled):not(.active):hover
    a {
    background-color: $opacity-gray-3;
    font-weight: inherit;
  }

  &.ngx-datatable .datatable-header .datatable-header-cell {
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 1em;
    padding-left: 7px;

    .sort-btn:after {
      opacity: 0.4;
      content: "\f0dc";
      position: relative;
      display: inline-block;
      bottom: 1px;
      right: -7px;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 12px;
    }
    .sort-btn.sort-asc.datatable-icon-up:after {
      content: "\f0de";
      opacity: 0.8;
      top: 2px;
    }
    .sort-btn.sort-desc.datatable-icon-down:after {
      content: "\f0dd";
      opacity: 0.8;
      top: -3px;
    }
    .sort-btn:before {
      display: none !important;
    }
  }

  &.ngx-datatable.bootstrap
    .datatable-header
    .datatable-header-cell:first-child {
    padding-left: 0;
  }

  &.ngx-datatable.bootstrap .datatable-body {
    &::-webkit-scrollbar {
      background-color: #fff;
      opacity: 0.3;
      width: 15px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border: 2.5px solid #fff;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }

  &.ngx-datatable.bootstrap .datatable-body .progress-linear {
    display: block;
    position: relative !important;
    width: 100%;
    height: 5px;
    padding: 0;
    margin: 0;
    position: absolute;
  }

  &.ngx-datatable.bootstrap .datatable-body .progress-linear .container {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 5px;
    -webkit-transform: translate(0, 0) scale(1, 1);
    transform: translate(0, 0) scale(1, 1);
    background-color: $white;
    max-width: 100% !important;
  }

  &.ngx-datatable.bootstrap .datatable-body .progress-linear .container .bar {
    transition: all 5s linear;
    -webkit-animation: query 3s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
    animation: query 3s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
    transition: -webkit-transform 0.5s linear;
    transition: transform 0.5s linear;
    background-color: $danger;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
  }

  @keyframes query {
    0% {
      opacity: 1;
      transform: translateX(-55%) scale(0, 1);
    }
    100% {
      opacity: 0;
      transform: translateX(65%) scale(0.3, 1);
    }
  }
}

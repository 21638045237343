/* You can add global styles to this file, and also import other style files */
:root {
  --amplify-primary-color: #e14eca;
  --amplify-primary-tint: #ba54f5;
  --amplify-primary-shade: #e14eca;
}


.pure-checkbox input[type=checkbox]+label:before {
  // color: #eb5592 !important;
  border: 1px solid #eb5592 !important;
}